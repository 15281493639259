/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import '~@angular/cdk/overlay-prebuilt.css';
@import '~ngx-sharebuttons/themes/default';

.ks-modal-gallery-backdrop {
  background: #000 !important;
  opacity: 0.85 !important;
}
.ks-modal-gallery-panel {
  z-index: 90000 !important;
}

ks-modal-gallery {
  #modal-gallery-wrapper {
    @apply w-screen;
  }
}

:root {
  --plyr-color-main: #ef4444;
}

.bg-pattern {
  background: linear-gradient(
      135deg,
      rgb(245, 245, 248) 22px,
      var(--fuse-primary-300) 22px,
      var(--fuse-primary-300) 24px,
      transparent 24px,
      transparent 67px,
      var(--fuse-primary-300) 67px,
      var(--fuse-primary-300) 69px,
      transparent 69px
    ),
    linear-gradient(
        225deg,
        rgb(245, 245, 248) 22px,
        var(--fuse-primary-300) 22px,
        var(--fuse-primary-300) 24px,
        transparent 24px,
        transparent 67px,
        var(--fuse-primary-300) 67px,
        var(--fuse-primary-300) 69px,
        transparent 69px
      )
      0 64px;
  background-color: rgb(245, 245, 248);
  background-size: 64px 128px;
}
